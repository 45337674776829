/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useState } from 'react'

type PlayerContextType = {
  kalturaScriptReady: boolean
  scriptCoolaReady: boolean
  scriptAkamaiReady: boolean
  scriptKantarReady: boolean
  targetPlayNowArr: string[]
  videoPlaying: boolean
  videoEndedId: string
  setKalturaScriptReady: (res: boolean) => void
  setCoolaScriptReady: (res: boolean) => void
  setAkamaisScriptReady: (res: boolean) => void
  setKantarScriptReady: (res: boolean) => void
  setTargetPlayingNow: (targetId: string, isUserClicked: boolean) => void
  setVideoPlaying: (res: boolean) => void
  setVideoEndedId: (res: string) => void
  //canLoadPlayers:boolean; TODO = dont load player if maavaron on
}

export const PlayerContext = React.createContext<PlayerContextType>({
  kalturaScriptReady: false,
  scriptCoolaReady: false,
  scriptAkamaiReady: false,
  scriptKantarReady: false,
  targetPlayNowArr: [],
  videoPlaying: false,
  videoEndedId: '',
  setKalturaScriptReady: () => {
    return
  },
  setCoolaScriptReady: () => {
    return
  },
  setAkamaisScriptReady: () => {
    return
  },
  setKantarScriptReady: () => {
    return
  },
  setTargetPlayingNow: () => {
    return
  },
  setVideoPlaying: () => {
    return
  },
  setVideoEndedId: () => {
    return
  },
})

type Props = {
  children: React.ReactNode
}

const PlayerContextProvider = ({ children }: Props) => {
  const [kalturaScriptReady, setKalturaScript] = useState(false)
  const [scriptCoolaReady, setCoolaScript] = useState(false)
  const [scriptAkamaiReady, setAkamaiScript] = useState(false)
  const [scriptKantarReady, setKantarScript] = useState(false)
  const [targetPlayNowArr, setTargetPlayNow] = useState<string[]>([])
  const [videoPlaying, setVideoPlay] = useState(false)
  const [videoEndedId, setVideoEndedId] = useState('')

  const setKalturaScriptReadyHandler = useCallback((res: boolean) => {
    setKalturaScript(res)
  }, [])

  const setCoolaScriptReadyHandler = useCallback((res: boolean) => {
    setCoolaScript(res)
  }, [])

  const setAkamaiScriptReadyHandler = useCallback((res: boolean) => {
    setAkamaiScript(res)
  }, [])

  const setKantarScriptReadyHandler = useCallback((res: boolean) => {
    setKantarScript(res)
  }, [])

  const setEntryRefPlayNowHandler = useCallback(
    (targetId: string, isUserClicked: boolean) => {
      if (isUserClicked) {
        setTargetPlayNow((prevState: string[]) => {
          if (prevState.includes(targetId)) {
            const newPlayArr = prevState.sort(function (x, y) {
              return x == targetId ? -1 : y == targetId ? 1 : 0
            })
            return [...newPlayArr]
          } else {
            return [targetId, ...prevState]
          }
        })
      } else {
        setTargetPlayNow(prevState => {
          const newArr = [...prevState, targetId]
          return newArr
        })
      }
    },
    []
  )

  const setVideoPlayHandler = useCallback((res: boolean) => {
    setVideoPlay(res)
  }, [])

  const setVideoEndedIdHandler = useCallback((res: string) => {
    setVideoEndedId(res)
  }, [])

  const contextValue: PlayerContextType = {
    kalturaScriptReady: kalturaScriptReady,
    scriptCoolaReady: scriptCoolaReady,
    scriptAkamaiReady: scriptAkamaiReady,
    scriptKantarReady: scriptKantarReady,
    targetPlayNowArr: targetPlayNowArr,
    videoPlaying: videoPlaying,
    videoEndedId: videoEndedId,
    setKalturaScriptReady: setKalturaScriptReadyHandler,
    setCoolaScriptReady: setCoolaScriptReadyHandler,
    setAkamaisScriptReady: setAkamaiScriptReadyHandler,
    setKantarScriptReady: setKantarScriptReadyHandler,
    setTargetPlayingNow: setEntryRefPlayNowHandler,
    setVideoPlaying: setVideoPlayHandler,
    setVideoEndedId: setVideoEndedIdHandler,
  }

  return (
    <PlayerContext.Provider value={contextValue}>
      {children}
    </PlayerContext.Provider>
  )
}

export default PlayerContextProvider
