export interface TimerState {
  time: number
  isRunning: boolean
  isPaused: boolean
  pauseTime: number
}

class DfpTimer {
  private time = 0
  private isRunning = false
  private isPaused = false
  private pauseTime = 0
  private intervalId: NodeJS.Timeout | null = null
  private pauseStart: number | null = null

  public getState(): TimerState {
    return {
      time: this.time,
      isRunning: this.isRunning,
      isPaused: this.isPaused,
      pauseTime: Math.floor(this.pauseTime / 60),
    }
  }

  public start(): void {
    if (!this.isRunning) {
      this.isRunning = true
      this.isPaused = false
      this.startInterval()
    }
  }

  private startInterval(): void {
    if (!this.intervalId) {
      this.intervalId = setInterval(() => {
        if (!this.isPaused) {
          this.time += 1
        }
      }, 1000)
    }
  }

  public pause(): void {
    if (this.isRunning && !this.isPaused) {
      this.isPaused = true
      this.pauseStart = Date.now()
    }
  }

  public resume(): void {
    if (this.isRunning && this.isPaused) {
      this.isPaused = false
      if (this.pauseStart !== null) {
        this.pauseTime += Date.now() - this.pauseStart
        this.pauseStart = null
      }
    }
  }

  public stop(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId)
      this.intervalId = null
    }
    this.isRunning = false
    this.isPaused = false
    this.time = 0
    this.pauseTime = 0
    this.pauseStart = null
  }
}

export default DfpTimer
