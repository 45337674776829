import Script from 'next/script'
import { Dispatch, SetStateAction } from 'react'
import { clientConfig } from '../client/clientConfig'
import { isBrowser } from '../client'
import { useWindow } from '../contexts'
import { useRootStore } from '../store'
import GlobalStyle from '../styles/GlobalStyle'
import { IPage } from '../types'
import { TaboolaLoader } from './TaboolaLoader'
import { usePlayerStore } from '../store/playerStore'

type Props = {
  playerEmbed?: boolean
  playerScript?: {
    kalturaReady: boolean
    SpringStreamsReady: boolean
    kantarReady: boolean
  }
  setPlayerScript?: Dispatch<
    SetStateAction<{
      kalturaReady: boolean
      SpringStreamsReady: boolean
      kantarReady: boolean
    }>
  >
  page: IPage
}

export const Scripts = ({ page, playerEmbed }: Props) => {
  const win = useWindow()
  const [setGooglePalReady] = usePlayerStore(state => [state.setGooglePalReady])
  const [isPlayerFullscreen, playerScript, setPlayerScript] = useRootStore(
    state => [
      state.isPlayerFullscreen,
      state.playerScript,
      state.setPlayerScript,
    ]
  )

  return (
    <>
      {Object.entries(page?.SEO?.schema || {}).map(el => {
        if (typeof el[1] == 'object') {
          return (
            <Script
              strategy='beforeInteractive'
              type='application/ld+json'
              dangerouslySetInnerHTML={{
                __html: JSON.stringify(el[1]),
              }}
              key={`schema-${el[0]}`}
            />
          )
        }
      })}
      {page.SiteMeta.config?.player?.sendPal === 'on' && (
        <Script
          key={'GooglePal'}
          strategy={'afterInteractive'}
          src={'https://imasdk.googleapis.com/pal/sdkloader/pal.js'}
          onReady={() => {
            setGooglePalReady(true)
          }}
        />
      )}

      <TaboolaLoader key={'TaboolaLoader'} />
      <Script
        key={'KalturaPlayer'}
        strategy={'afterInteractive'}
        src={`https://cdnapisec.kaltura.com/p/2748741/embedPlaykitJs/uiconf_id/${clientConfig.kalturaPlayerId}`}
        onReady={() => setPlayerScript({ ...playerScript, kalturaReady: true })}
      />

      {page?.SiteMeta.config?.player?.kantar?.enable &&
        playerScript.kalturaReady && (
          <Script
            key={'kaltura-playkit-kantar'}
            strategy={'afterInteractive'}
            src={'/cdn/kaltura/playkit-kantar.js'}
            onReady={() => {
              setPlayerScript({
                ...playerScript,
                kantarReady: true,
              })
            }}
            onError={() => {
              console.log(
                '%c[[...all]]:',
                'background-color:darkred;color:white;padding:2px 5px;',
                'kantar script load failed'
              )
            }}
          />
        )}

      {!playerEmbed && win && isBrowser() ? (
        <Script
          key={'accessibility'}
          strategy={'afterInteractive'}
          src={'/cdn/accessibility/accessibility_source.js'}
          id={'accessibilityIncSource'}
          data-username={'iav2b023'}
          data-version={'5'}
          data-source='package'
          data-language={'he'}
        />
      ) : null}
      {/* Google Tag Manager (noscript) */}
      <noscript
        dangerouslySetInnerHTML={{
          __html: `<iframe src='https://www.googletagmanager.com/ns.html?id=${clientConfig.googleTagManager}' height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
        }}
      />
      {/* End Google Tag Manager (noscript) */}
      {page?.SiteMeta.config?.features?.heyday === 'on' && (
        <Script
          src={`https://admin.heyday.io/cstmst/heyDayMain.js?affId=2470&d=${
            process.env.HEY_DAY_SEARCH_SOURCE || '13tv.co.il'
          }`}
          async
          key={'heyDay'}
          id={'heyDay'}
          type={'text/javascript'}
          strategy={'beforeInteractive'}
        />
      )}

      <GlobalStyle isPlayerFullscreen={isPlayerFullscreen} />
    </>
  )
}
